<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          @click="turnOnActionSidebar"
        >
          <span class="align-middle">SNS인증문제 확인</span>
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        {{ data.value }}
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(inf_idx)="data">
        <div
          v-if="data.item.inf_idx != null"
          class="d-flex"
        >
          <div>
            {{ data.item.inf_name }}
          </div>
          <BLink
            :to="{ name: 'member-inf-detail', params: { idx: data.value } }"
          >
            ({{ data.value }})
          </BLink>
        </div>
      </template>

      <template #cell(service)="data">
        <BAvatarGroup>
          <TagbySnsAvatarV2 :channel="data.value" />
        </BAvatarGroup>
      </template>

      <template #cell(profile_img_url)="data">
        <BLink
          :href="`${ data.item.landing_url }`"
          target="_blank"
        >
          <BAvatar
            size="30"
            :src="data.value"
          />
        </BLink>
      </template>

      <template #cell(category)="data">
        <BBadge
          v-for="(category, i) in data.value"
          :key="i"
          style="margin-right: 5px"
          variant="primary"
        >
          {{ category }}
        </BBadge>
      </template>
    </TagbyList>
    <Sidebars />
    <Modals />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
  BAvatarGroup,
  BBadge,
  BAvatar,
} from 'bootstrap-vue'
import useInitialize from './hooks/useInitialize'
import useItems from './hooks/useItems'
import useState from './hooks/useState'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbySnsAvatarV2 from '@/components/TagbySnsAvatarV2.vue'
import state from './state'
import getters from './getters'
import useActioinSidebar from './hooks/useActioinSidebar'
import Sidebars from './components/Sidebars'
import Modals from './components/Modals'

export default defineComponent({
  components: {
    BLink,
    BButton,
    BAvatar,
    BAvatarGroup,
    BBadge,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    TagbySnsAvatarV2,
    Sidebars,
    Modals,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    const {
      turnOnSidebar: turnOnActionSidebar,
    } = useActioinSidebar()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
      turnOnActionSidebar,
    }
  },
})
</script>
