<template>
  <BAvatar
    v-if="channel === 'INSTA_BASIC'"
    rounded
    variant="warning"
    class="pull-up"
  >
    <span>INS</span>
  </BAvatar>
  <BAvatar
    v-else-if="channel === 'INSTA_BIZ'"
    rounded
    variant="warning"
    class="pull-up"
  >
    <span>BIZ</span>
  </BAvatar>
  <BAvatar
    v-else-if="channel === 'NAVER_BLOG'"
    rounded
    variant="success"
    class="pull-up"
  >
    <span>BLG</span>
  </BAvatar>
  <BAvatar
    v-else-if="channel === 'YOUTUBE'"
    rounded
    variant="danger"
    class="pull-up"
  >
    <span>YTB</span>
  </BAvatar>
  <BAvatar
    v-else-if="channel === 'TIKTOK'"
    rounded
    variant="dark"
    class="pull-up"
  >
    <span>TTK</span>
  </BAvatar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BAvatar,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BAvatar,
  },
  props: {
    channel: {
      type: String,
      required: true,
    },
  },
  setup() {
  },
})
</script>
