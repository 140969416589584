import {
  computed,
} from '@vue/composition-api'

import useToast from '@/utils/toast'

import state from '../state'
import api from '../api'

import useErroReasonModal from './useErroReasonModal'

export default () => {
  const { makeToast } = useToast()
  const { turnOnModal: turnOnErrorReasonModal } = useErroReasonModal()

  const errorReason = computed(() => {
    if (state.actionSidebar.action == null) {
      return null
    }
    if (!state.actionSidebar.action.has_error) {
      return null
    }
    if (!state.actionSidebar.action.error_reason) {
      return '검색용 SNS 토큰 문제'
    }
    return state.actionSidebar.action.error_reason
  })

  const turnOnSidebar = () => {
    state.actionSidebar.isVisible = true
    state.actionSidebar.tokenProviderName = 'thschsmd.tagby'
  }
  const turnOffSidebar = () => {
    state.actionSidebar.isVisible = false
  }

  const getRecentAction = () => {
    state.actionSidebar.isGetting = true
    return api.getRecentAction({
      username: state.actionSidebar.username,
    }).then(response => {
      state.actionSidebar.action = response.data.data
    }).catch(err => {
      makeToast('danger', '최근 인증시도를 가져오는데 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.actionSidebar.isGetting = false
    })
  }

  const pullUpToken = () => {
    state.actionSidebar.isPulling = true
    return api.pullUpToken({
      username: state.actionSidebar.tokenProviderName,
    }).then(() => {
      makeToast('primary', 'SNS인증 오류를 수정하는데 성공했습니다', '인플루언서에게 재인증을 요청하세요')
    }).catch(err => {
      makeToast('danger', 'SNS인증 오류를 수정하는데 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.actionSidebar.isPulling = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    getRecentAction,
    pullUpToken,
    errorReason,
  }
}
