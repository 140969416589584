<template>
  <TagbySidebar
    :visible="state.actionSidebar.isVisible"
    title="SNS인증문제 확인"
    @hidden="turnOffSidebar"
  >
    <BFormGroup label="인증문제 확인할 username">
      <BInputGroup>
        <BFormInput v-model="state.actionSidebar.username" />
        <BInputGroupAppend>
          <BButton
            variant="outline-secondary"
            :disabled="state.actionSidebar.isGetting"
            @click="getRecentAction"
          >
            <BSpinner
              v-if="state.actionSidebar.isGetting"
              small
            />
            확인
          </BButton>
        </BInputGroupAppend>
      </BInputGroup>
    </BFormGroup>

    <div
      v-if="state.actionSidebar.action != null"
      class="mb-1"
    >
      <div
        v-if="!state.actionSidebar.action.has_error"
        class="text-success"
      >
        인증 성공
      </div>
      <div v-else>
        <div class="text-danger">
          {{ errorReason }}
        </div>
        <div
          v-if="errorReason === '검색용 SNS 토큰 문제'"
          class="text-info"
        >
          유저를 검색할때 다른 유저의 토큰으로 검색을 실행합니다.
          검색에 사용되는 유저의 토큰에 문제가 생긴 상황입니다.
          검색용 SNS를 재설정 하십시오. 개발팀이 아니라면 기본적으로 세팅된 username을 사용하시면 됩니다.
        </div>

      </div>
    </div>

    <BFormGroup
      v-if="errorReason === '검색용 SNS 토큰 문제'"
      label="토큰 재설정 username"
    >
      <BInputGroup>
        <BFormInput v-model="state.actionSidebar.tokenProviderName" />
        <BInputGroupAppend>
          <BButton
            variant="outline-secondary"
            :disabled="state.actionSidebar.isPulling"
            @click="pullUpToken"
          >
            <BSpinner
              v-if="state.actionSidebar.isPulling"
              small
            />
            확인
          </BButton>
        </BInputGroupAppend>
      </BInputGroup>
    </BFormGroup>

    <div>
      <BButton
        variant="outline-secondary"
        @click="turnOffSidebar"
      >
        닫기
      </BButton>
    </div>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import state from '../../state'
import useActioinSidebar from '../../hooks/useActioinSidebar'

export default defineComponent({
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BSpinner,
    TagbySidebar,
  },
  setup() {
    const {
      turnOffSidebar,
      getRecentAction,
      pullUpToken,
      errorReason,
    } = useActioinSidebar()
    return {
      state,
      turnOffSidebar,
      getRecentAction,
      pullUpToken,
      errorReason,
    }
  },
})
</script>
