export default () => ({
  currentPage: null,
  perPage: null,
  totalRows: null,
  snsList: [],
  categoryList: [],
  admList: [],
  isBusy: false,
  filterParams: {},
  sortBy: '-idx',
  isLoading: false,
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
  actionSidebar: {
    isVisible: false,
    isGetting: false,
    isPulling: false,
    username: null,
    action: null,
    tokenProviderName: null,
  },
})
