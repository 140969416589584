<template>
  <ActionSidebar />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ActionSidebar from './ActionSidebar.vue'

export default defineComponent({
  components: {
    ActionSidebar,
  },
  setup() {

  },
})
</script>
