import {
  reactive,
  computed,
} from '@vue/composition-api'

import state from './state'

import {
  indexing,
  grouping,
} from '@/utils/value'

const items = computed(() => {
  const categoryByIdx = indexing(state.categoryList, 'sns_idx')
  return state.snsList.map(sns => {
    const category = categoryByIdx[sns.idx]
    return {
      ...sns,
      category: category?.categories ?? ['분류 전'],
    }
  })
})

export default reactive({
  items,
})
