import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from '../state'
import api from '../api'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: '닉네임',
      paramName: 'username',
    },
    {
      label: '인플루언서명',
      paramName: 'inf_name',
    },
    {
      label: '상태',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'ACTIVE', value: 'ACTIVE' },
        { text: 'RELOGIN', value: 'RELOGIN' },
        { text: 'INACTIVE', value: 'INACTIVE' },
      ],
    },
    {
      label: 'SNS채널',
      paramName: 'channel',
      format: 'checkbox',
      options: [
        { text: 'INSTA_BASIC', value: 'INSTA_BASIC' },
        { text: 'INSTA_BIZ', value: 'INSTA_BIZ' },
        { text: 'NAVER_BLOG', value: 'NAVER_BLOG' },
        { text: 'YOUTUBE', value: 'YOUTUBE' },
        { text: 'TIKTOK', value: 'TIKTOK' },
      ],
    },
    {
      label: 'ISI',
      paramName: 'isi',
      format: 'range',
    },
    {
      label: '평균도달',
      paramName: 'avg_reach',
      format: 'range',
    },
    {
      label: '평균참여',
      paramName: 'avg_engagement',
      format: 'range',
    },
    {
      label: '네이버 인플루언서 여부',
      paramName: 'is_naver_inf',
      format: 'checkbox',
      options: [
        { text: 'true', value: true },
        { text: 'false', value: false },
      ],
    },
  ]

  const searchSnsList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.snsList = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast('danger', '목록을 가져오는데 실패 했습니다')
    }).finally(() => {
      state.isBusy = false
    })
  }

  const searchCategory = snsIdxList => api.searchCategory({
    sns_idx_list: snsIdxList,
  }).then(response => {
    state.categoryList = response.data.data.map(r => ({ ...r, sns_idx: parseInt(r.sns_idx, 10) }))
  }).catch(() => {
    makeToast('danger', '카테고리를 가져오는데 실패 했습니다')
  })

  const searchList = () => {
    searchSnsList().then(() => {
      const snsIdxList = values(state.snsList, 'idx')
      searchCategory(snsIdxList)
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: 'idx' },
    { key: 'inf_idx', label: '회원' },
    { key: 'service', label: '서비스' },
    { key: 'profile_img_url', label: 'PIC' },
    { key: 'username' },
    { key: 'category' },
    { key: 'post_count', label: '포스트' },
    { key: 'follower_count', label: '팔로워' },
    { key: 'isi' },
    { key: 'avg_reach', label: '평균도달' },
    { key: 'avg_engagement', label: '평균참여' },
    { key: 'state', label: '상태' },
    { key: 'naver_inf_idx' },
  ]

  const changePage = params => {
    state.currentPage = params.page
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
